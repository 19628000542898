const Warning = ({ ...props }) => {
    return (
        <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.867 0.00386509C14.0027 0.0555346 13.2173 0.596537 12.7846 1.43908L0.334108 25.6862C-0.63188 27.569 0.616034 29.9998 2.54947 30H27.4505C29.384 29.9998 30.6319 27.569 29.6659 25.6862L17.2154 1.43908C16.7353 0.504032 15.8262 -0.052138 14.867 0.00386509ZM14.9437 1.67774C15.3464 1.65441 15.7222 1.88323 15.9235 2.27592H15.926L28.3766 26.5228C28.8051 27.3581 28.3081 28.3231 27.4505 28.3231H2.54947C1.69185 28.3231 1.19493 27.3581 1.62342 26.5228L14.074 2.27592H14.0765C14.2576 1.92257 14.5814 1.69924 14.9437 1.67774H14.9437ZM16.5 23.3341C16.5 23.7762 16.342 24.2002 16.0606 24.5128C15.7793 24.8254 15.3978 25.0009 14.9999 25.0009C14.6021 25.0009 14.2205 24.8254 13.9392 24.5128C13.6579 24.2002 13.4998 23.7762 13.4998 23.3341C13.4998 22.8921 13.6579 22.4683 13.9392 22.1557C14.2205 21.8431 14.6021 21.6674 14.9999 21.6674C15.3978 21.6674 15.7793 21.8431 16.0606 22.1557C16.342 22.4683 16.5 22.8921 16.5 23.3341ZM14.2499 19.9992V8.33191H15.75V19.9992H14.2499Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default Warning
