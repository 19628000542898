import cn from 'classnames'
import React, { Dispatch, ReactElement, SetStateAction } from 'react'
import s from '@corratech/pylot-quantity-selector/src/QuantitySelector.module.scss'

type QuantitySelectorProps = {
    quantity: number
    setQuantity: Dispatch<SetStateAction<number>> | ((value: any) => void)
    isEditingDisabled?: boolean
}

const MIN = 1

const getBoundedValue = (value = 0): number => {
    if (value < MIN) return MIN
    return Math.floor(value)
}

export const QuantitySelector = ({
    quantity,
    setQuantity,
    isEditingDisabled = false
}: QuantitySelectorProps): ReactElement => (
    <div className={cn(s['pdp-quantity'], 'pdp-qty')}>
        <input
            disabled={isEditingDisabled}
            type="number"
            min={MIN}
            className={s.quantity}
            value={quantity ?? ''}
            id="input-quantity"
            onBlur={(e) => {
                const newValue = getBoundedValue(+e.target.value)
                setQuantity(newValue)
                // sometimes state changes do not reflect the input value, therefore
                //  value should be set explicitly
                e.target.value = newValue.toString()
            }}
            onChange={(e) => {
                setQuantity(parseInt(e.target.value))
            }}
        />
    </div>
)
