import Script from 'next/script'
import React, { useEffect } from 'react'
const STRATEGY = 'afterInteractive'
interface HubSpotFormAttributes {
    target: string
    region: string
    portalId: string
    formId: string
}

export interface HubSpotFormProps extends HubSpotFormAttributes {
    enabled: boolean
    src: string
}

declare global {
    interface Window {
        hbspt: {
            forms: {
                create: (attributes: HubSpotFormAttributes) => void
            }
        }
    }
}

export const HubSpotForm: React.FC<HubSpotFormProps> = ({
    enabled = false,
    src = '',
    target = '',
    region = '',
    portalId = '',
    formId = ''
}) => {
    const onLoad = () => {
        if (typeof window !== 'undefined' && window.hbspt && enabled) {
            window.hbspt.forms.create({
                region,
                portalId,
                formId,
                target
            })
        }
    }
    useEffect(onLoad, [enabled, region, portalId, formId, target])

    if (!enabled) return null

    // Parse target in 'id' and/or 'className'
    let id = ''
    let className = ''

    // Split the target string by spaces to handle multiple selectors
    const selectors = target.split(/\s+/)

    selectors.forEach((selector) => {
        if (selector.startsWith('#')) {
            // Extract ID value (remove the '#' and keep the rest)
            id = selector.substring(1)
        } else if (selector.startsWith('.')) {
            // Extract class value (remove the '.' and keep the rest)
            // Concatenate multiple class names if necessary
            className += (className ? ' ' : '') + selector.substring(1)
        }
    })

    return (
        <>
            <div id={id} className={className} />
            <Script async src={src} onLoad={onLoad} strategy={STRATEGY} />
        </>
    )
}
