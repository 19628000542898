const Success = ({ ...props }) => {
    return (
        <svg
            height="30"
            viewBox="0 0 30 30"
            width="30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.66645 27.472C9.13319 29.1203 12.0333 30 15 30C18.977 29.9959 22.7899 28.4142 25.602 25.602C28.4142 22.7899 29.9959 18.977 30 15C30 12.0333 29.1203 9.13319 27.472 6.66645C25.8238 4.19972 23.4811 2.27713 20.7403 1.14181C17.9994 0.00649929 14.9834 -0.290551 12.0737 0.288228C9.16394 0.867006 6.49119 2.29562 4.3934 4.3934C2.29562 6.49119 0.867006 9.16394 0.288228 12.0737C-0.290551 14.9834 0.00649929 17.9994 1.14181 20.7403C2.27713 23.4811 4.19972 25.8238 6.66645 27.472ZM7.70815 4.08697C9.86654 2.64477 12.4041 1.87501 15 1.87501C18.481 1.87501 21.8194 3.25782 24.2808 5.71923C26.7422 8.18064 28.125 11.519 28.125 15C28.125 17.5959 27.3552 20.1335 25.913 22.2919C24.4709 24.4503 22.421 26.1325 20.0227 27.1259C17.6244 28.1193 14.9854 28.3792 12.4394 27.8728C9.89345 27.3664 7.55479 26.1163 5.71923 24.2808C3.88367 22.4452 2.63363 20.1066 2.1272 17.5606C1.62077 15.0146 1.88069 12.3756 2.87409 9.97729C3.86749 7.57901 5.54975 5.52916 7.70815 4.08697ZM12.4812 19.0375C12.6569 19.2133 12.8952 19.3122 13.1437 19.3125C13.2673 19.3128 13.3898 19.2886 13.504 19.2414C13.6182 19.1942 13.722 19.1249 13.8093 19.0375L20.625 12.2375C20.8007 12.0597 20.8986 11.8194 20.8971 11.5694C20.8956 11.3195 20.7949 11.0804 20.6172 10.9047C20.4394 10.7289 20.1991 10.6311 19.9491 10.6325C19.6992 10.634 19.46 10.7347 19.2843 10.9125L13.1437 17.0406L10.7156 14.6125C10.5379 14.4469 10.3028 14.3567 10.0599 14.361C9.81706 14.3653 9.58533 14.4637 9.41356 14.6354C9.2418 14.8072 9.14341 15.0389 9.13912 15.2818C9.13484 15.5247 9.22499 15.7597 9.39059 15.9375L12.4812 19.0375Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default Success
